.styPagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
}

.styPagination > li {
  margin-left: 10px;
}

.styPagination > li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  cursor: pointer;
}

.styPagination > li.previous a,
li.next a,
li.break a {
  border-color: transparent;
}

.styPagination > li.active a, .selected {
  border-radius: 7px;
  background-color: #8D0000;
  border-color: transparent;
  color: white;
  min-width: 32px;
}

.styPagination > li.disabled a {
  color: grey;
}

.styPagination > li.disable,
li.disabled a {
  cursor: default;
}