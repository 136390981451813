.styTextBox > ol {
    padding-left: 20px;
}

.styTextBox > h1 {
  font-size: 1.875rem;
}

.styTextBox > h2 {
  font-size: 1.5rem;
}

.styTextBox > h3 {
  font-size: 1.25rem;
}