body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fontRegular {
  font-weight: 500;
}

.fontItalic {
  font-style: italic;
}

.fontSemiBold {
  font-weight: 600;
}

.fontBold {
  font-weight: 700;
}

.slick-center {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  transition: transform 0.3s;
}

@media screen and (min-width: 768px) {
  /* .shownALF:hover, .hiddenALF {
    display: none;
  }
  
  .shownALF:hover + .hiddenALF {
    z-index: 0;
    opacity: 1;
    display: inline-block;
  } */
  .ant-upload {
    border: none;
  }

  .red-hover:hover  {
   
    background-color: #8D0000;
  }

  .red-hover:hover .shownALF{
    display: none;
  }
  
   .shownALF+ .hiddenALF{

    z-index: 0;
    opacity: 1;
    display: inline-block;
   }
}

@media screen and (max-width: 768px) {
  .hiddenALF {
    display: none;
  }
}

.centering {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.ant-upload {
  border: none;
}

.slick-prev {
  left: 4% !important;
  z-index: 1;
}
.slick-next {
  right: 4% !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: black!important;
  font-size: 35px !important;
}